import useSWR from 'swr';
import type { UI } from '@/v2/types';
import useSearchQuery from '@experiences-ui/shared/v2/hooks/useSearchQuery';
import validateSearchQuery from '@/utils/validateSearchQuery';
import qs from '@/lib/qs';
import { DEFAULT_SEARCH_PARAMS } from '@/constants';

export type UsePackageOffersProps = {
  propertyId?: string;
  destinationSearchConfig: UI.Destination['searchConfig'];
};

type SWRReturnType = {
  packageOffers: UI.PackageOffer[];
  availability: unknown;
  flightOptions: UI.FlightOptions | undefined;
  qffPointTiers: UI.QFFPointTiersInstance | undefined;
};

const usePackageOffers = ({
  propertyId,
  destinationSearchConfig,
}: UsePackageOffersProps) => {
  const { searchQuery } = useSearchQuery();
  const { originCode, departureDate, returnDate, adults, children, infants } =
    searchQuery || {};

  const isValidSearchQuery =
    //TODO: add package option validation
    propertyId && validateSearchQuery(destinationSearchConfig, searchQuery);

  const searchParams = {
    propertyId: propertyId,
    destinationCode: destinationSearchConfig?.destinationCode,
    ...DEFAULT_SEARCH_PARAMS,
    originCode,
    departureDate,
    returnDate,
    adults,
    children,
    infants,
  };

  // TODO: get types for 'data'
  const { data, error } = useSWR<SWRReturnType>(() => {
    if (!isValidSearchQuery) {
      return null;
    }

    return qs.stringifyUrl({
      url: '/package/offers',
      query: searchParams,
    });
  });

  const loading = !data && !error && !!isValidSearchQuery;

  return {
    searchParams,
    searchQuery,
    isPreSearch: !isValidSearchQuery,
    error,
    loading,
    availability: data && data.availability,
    flightOptions: data && data.flightOptions,
    packageOffers: data && data.packageOffers,
    holidaysPointsTiers: data && data.qffPointTiers,
  };
};

export default usePackageOffers;
