import type { UI } from '@/v2/types';
import { createCtx } from '@/v2/utils/createCtx';
import { CtaEvent } from '@/libs/v2/components/createRoomOptionCTA/types';
import useCtaOnClick, {
  OnCtaClickOpts,
} from '@/v2/components/pages/PackageView/components/RoomOptions/hooks/useCtaOnClick/useCtaOnClick';
import { ReactNode, useMemo } from 'react';
import useRoute from '@/hooks/useRoute';
import { BEST_RATE_ID } from '@/libs/v2/constants/packages';
import { getTotalTravellers } from '@/libs/utils';
import useAutoSelectedRoomOption from '@/libs/v2/hooks/useAutoSelectedRoomOption';
import useRooms from '@/v2/components/pages/PackageView/hooks/useRooms';
import usePackageOptions from '@/v2/components/pages/PackageView/hooks/usePackageOptions';
import { PackageOption } from '@/libs/v2/utils/getPackageOptions';
import useOfferUpgrade from '@/libs/v2/hooks/useOfferUpgrade';
import { useSearchPanelControls } from '../SearchPanelControlsContext';

export type RoomOptionsDataProviderProps = {
  propertyContent: UI.Property;
  procuredOffer: UI.ProcuredOffer | null;
  children: ReactNode;
};

export type RoomOptionsContextValue = {
  isOriginAndPackageSelected: boolean;
  route: string;
  isPreSearch: boolean;
  isBestRates: boolean;
  roomOptions:
    | {
        all: UI.RoomOption[] | undefined;
        main: UI.RoomOption[] | null;
        other: UI.RoomOption[] | null;
      }
    | undefined;
  selectedRoomOption: UI.RoomOption | undefined;
  isLoading: boolean;
  travellerCount: number | undefined | null;
  onCtaClick: (event: CtaEvent, opts: OnCtaClickOpts) => void;
  tiers: UI.QFFPointTiersInstance | undefined;
  packageOffers: UI.PackageOffer[] | undefined;
  packageOptions: PackageOption[] | undefined;
  selectedPackageOption: PackageOption | undefined;
  upgradeOffer: UI.RoomOptionOffer | undefined;
};

const [useRoomOptionsData, Provider] = createCtx<RoomOptionsContextValue>();

const RoomOptionsDataProvider = ({
  children,
  propertyContent,
  procuredOffer,
}: RoomOptionsDataProviderProps) => {
  const { setCalendarRoomId } = useSearchPanelControls();

  // Room Options PAVA Data
  const {
    roomOptions,
    packageOffers,
    holidaysPointsTiers,
    searchQuery,
    isPreSearch,
    loading,
  } = useRooms({
    propertyContent,
    procuredOffer,
  });

  // Package Options
  const { packageOptions, selectedPackageOption } = usePackageOptions({
    procuredOffer,
    originCode: searchQuery?.originCode,
  });

  // Assorted re-usable data
  const route = useRoute();
  const onCtaClick = useCtaOnClick();
  const isOriginAndPackageSelected = !!(
    searchQuery?.packageOption && searchQuery?.originCode
  );
  const isBestRates = searchQuery?.packageOption === BEST_RATE_ID;
  const travellerCount = getTotalTravellers(searchQuery);

  // Room Selection
  const selectedRoomOption = useAutoSelectedRoomOption({
    isLoading: loading,
    isPreSearch,
    roomOptions: roomOptions.all,
    setCalendarRoomId,
  });

  // Upgrade Room Option Offer
  const upgradeOffer = useOfferUpgrade({
    inclusionSets: procuredOffer?.inclusionSets,
    selectedRoomOption,
  });

  // Final context value
  const value = useMemo(() => {
    return {
      isOriginAndPackageSelected,
      route,
      isPreSearch,
      isBestRates,
      selectedRoomOption,
      isLoading: loading,
      travellerCount,
      onCtaClick,
      tiers: holidaysPointsTiers,
      roomOptions,
      packageOffers,
      packageOptions,
      selectedPackageOption,
      upgradeOffer,
    };
  }, [
    isOriginAndPackageSelected,
    route,
    isPreSearch,
    isBestRates,
    selectedRoomOption,
    loading,
    travellerCount,
    onCtaClick,
    holidaysPointsTiers,
    roomOptions,
    packageOffers,
    packageOptions,
    selectedPackageOption,
    upgradeOffer,
  ]);

  return <Provider value={value}>{children}</Provider>;
};

export { useRoomOptionsData, RoomOptionsDataProvider };
