import usePackageOffers from '../usePackageOffers';
import type { UI } from '@/v2/types';
import useGroupedRoomOptions from '@/libs/v2/hooks/useGroupedRoomOptions';

export type UseRoomsProps = {
  propertyContent?: UI.Property;
  procuredOffer: UI.ProcuredOffer | null | undefined;
};

const useRooms = ({ propertyContent, procuredOffer }: UseRoomsProps) => {
  const {
    isPreSearch,
    searchQuery,
    packageOffers,
    loading,
    holidaysPointsTiers,
    flightOptions,
  } = usePackageOffers({
    propertyId: propertyContent?.id,
    destinationSearchConfig: propertyContent?.destination.searchConfig,
  });
  const { originCode, packageOption } = searchQuery || {};

  const roomOptions = useGroupedRoomOptions({
    packageOffers,
    procuredOfferRooms: procuredOffer?.rooms,
    roomTypes: propertyContent?.roomTypes,
    selectedOrigin: originCode,
    selectedInclusionSetId: packageOption,
  });

  return {
    roomOptions,
    loading,
    searchQuery,
    isPreSearch,
    packageOffers,
    flightOptions,
    holidaysPointsTiers,
  };
};

export default useRooms;
