import { CtaEvent } from '@/libs/v2/components/createRoomOptionCTA/types';
import { useCallback } from 'react';
import type { UI } from '@/v2/types';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { useScrollIntoView } from '@/libs/v2/components/InView';
import { useSectionControls } from '@/v2/components/pages/PackageView/contexts/SectionControlsContext';
import { useSearchPanelControls } from '@/v2/components/pages/PackageView/contexts/SearchPanelControlsContext';
import scrollTo from '@/libs/v2/utils/scrollIntoView';
import useBreakpoints, { isScreen } from '@/libs/v2/hooks/useBreakpoints';
import { roomCtaDataLayerEvent } from '@/v2/components/pages/PackageView/utils/dataLayer/dataLayer';
import dataLayer from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

export type OnCtaClickOpts = {
  roomOption?: UI.RoomOption | undefined;
  roomIndex?: number;
  ctaText?: string;
  ctaVariant?: string;
  shouldScrollIntoView?: boolean;
};

const useCtaOnClick = () => {
  const shouldUseNewEvents = useGA4Events();
  const scrollIntoView = useScrollIntoView();
  const { packageOptions } = useSectionControls();
  const { openCalendar, openTravellers, openMobileSearchPanelModal } =
    useSearchPanelControls();

  // Screen size info
  const breakpoints = useBreakpoints();
  const isDesktopScreen = isScreen(breakpoints)('md', 'lg');

  const { setSearchQuery } = useSearchQuery();
  return useCallback(
    (
      event: CtaEvent,
      {
        roomOption,
        roomIndex,
        ctaVariant,
        ctaText,
        shouldScrollIntoView = true,
      }: OnCtaClickOpts,
    ) => {
      if (ctaText && roomIndex) {
        roomCtaDataLayerEvent({ action: ctaText, roomIndex });
      }

      // We currently trigger the SELECT_ROOM event elsewhere, as part of Package Page v3,
      // so we don't want to duplicate the event call.
      if (shouldUseNewEvents && event !== 'SELECT_ROOM') {
        dataLayer.ctaClickEvent({
          itemText: ctaText || '',
          itemVariant: ctaVariant,
        });
      }

      switch (event) {
        case 'SELECT_ROOM':
          setSearchQuery({ room: roomOption?.roomType.id });
          return;
        case 'CHANGE_DATES':
          if (!isDesktopScreen) {
            openMobileSearchPanelModal();
          } else if (shouldScrollIntoView) {
            scrollTo('#date-range', { offset: -70, behavior: 'smooth' });
          }
          openCalendar(roomOption?.roomType.id);
          return;
        case 'CHANGE_TRAVELLERS':
          if (!isDesktopScreen) {
            // Don't open travellers for mobile
            return openMobileSearchPanelModal();
          } else if (shouldScrollIntoView) {
            scrollTo('#travellers', { offset: -100, behavior: 'smooth' });
          }
          openTravellers();
          return;
        case 'CHANGE_PACKAGE_OPTION':
          packageOptions.open();
          if (shouldScrollIntoView) {
            scrollIntoView('package', { behavior: 'smooth' });
          }
          return;
        default:
      }
    },
    [
      isDesktopScreen,
      openCalendar,
      openMobileSearchPanelModal,
      openTravellers,
      packageOptions,
      scrollIntoView,
      setSearchQuery,
    ],
  );
};

export default useCtaOnClick;
